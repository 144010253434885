import React, { Component } from 'react';
import axios from 'axios'
import { validEmailRegex, strongPasswordRegex, usernameRegex, mobilenumberRegex, validateForm } from '../../RegxConst'
import authservice from '../service/authservice';
import { TermsAndConditions } from './TermsAndConditions';
import { container, notifyUser, type } from '../../utility/notifyUser';
import Loader from '../../utility/Loader';
import styles from '../authentication.module.css';
import { ButtonStyle } from '../../UIElements/Buttons/ButtonStyle';
import SetAdvertiseLocation from '../../SetAdvertiseLocation';
import priviliege from '../../../assets/priviliege.gif'
import goodbye from '../../../assets/goodbye.gif'
import discount from '../../../assets/discount.gif'
import limited from '../../../assets/limited.gif'
import FooterStyle from '../../footer/FooterStyle.module.css'
import aajubaajuImg from '../../../assets/aajubaajuImg.svg'
import indianPlatform from '../../../assets/indianPlatform.svg'
import entrepreneurshipImg from '../../../assets/entrepreneurshipImg.svg'
import employmentImg from '../../../assets/employmentImg.svg'
import digitalImg from '../../../assets/digitalImg.svg'
import ablogo from '../../../assets/ablogo.svg'
import { Link } from 'react-router-dom'
import monthlyOffer from '../../../assets/monthlyOffer.gif'

class Registration extends Component {

    constructor(props) {
        super(props);
        this.addressOptions = [];
        this.selectedNeighbourhood = [];
        this.selectedLocality = [];
        this.selectedCity = [];
        this.selectedState = [];
        this.stateValidation = null;
        this.cityValidation = null;
        this.localityValidation = null;
        this.neighbourhoodValidation = null;
    }

    state = {
        firstName: '',
        isPopupOpen: false,
        lastName: '',
        username: '',
        email_address: '',
        password: '',
        confirmpassword: '',
        contact_number: '',
        tc: null,
        account_type: null,
        checked: false,
        age_checked: false,
        hasBusiness: false,
        canWriteContent: false,
        canCreateVideo: false,
        canCreatePhoto: false,
        canInfluence: false,
        canDoDigitalMarketing: false,
        canDoPRJournalism: false,
        checkError: '',
        ageCheckError: '',
        radioError: '',
        referralKey: '',
        profilePhotoUrl: null,
        // profilePhoto: '',
        errors: {
            username: '',
            email_address: '',
            password: '',
            contact_number: '',
            refKey: '',
            confirmpassword: '',
        },
        termsModel: {
            open: false,
            model_type: '',
        },
        selectedOption: '',
        yearlyAccount: false,
        monthlyPrepaidAccount: false,
        freeAccount: false,
        noTargetHalfYearlyAccount: false,
        halfYearlyPrepaidAccount: false,
        quarterlyPrepaidAccount: false,
        selectedNeighbourhood: [],
        selectedLocality: [],
        selectedCity: [],
        selectedState: [],
        fullAddress: [],
        prepaidStatus: '',
        prepaidPercentage: ''
    }

    componentDidMount() {
        this.prepaidOffer()
        Promise.all([
            axios.get(process.env.REACT_APP_BACKEND_URL + '/hpmgr/listing/getallstates'),
        ]).then(([getFullAddress]) => { // use arrow function to avoid loosing context, BTW you don't need to use axios.spread with ES2015 destructuring
            this.setState({ fullAddress: getFullAddress.data });
            //this.props.fullAddress(getFullAddress.data)
            Loader.stopSpinner()
        }).catch(error => {
            Loader.stopSpinner()
            console.log(error, 'getting data failed');
        });

        window.scrollTo(0, 0); //while loading the component it will focus top
        if (authservice.getCurrentUser() !== null) {
            this.props.history.push('/')
        }
        const search = this.props.history.location.search
        const refKey = new URLSearchParams(search).get("ref")
        if (refKey) {
            this.handleRefKey(refKey)
        }
    }

    _handleState = (selectedStates) => {
        if (selectedStates.length)
            this.selectedState = selectedStates;
        else
            this.selectedState = [];
    }

    _handleCity = (e) => {
        if (e.length)
            this.selectedCity = e;
        else
            this.selectedCity = [];
    }
    _handleLocality = (e) => {
        if (e.length)
            this.selectedLocality = e;
        else
            this.selectedLocality = [];
    }
    _handleNeighbourhood = (e) => {
        if (e.length)
            this.selectedNeighbourhood = e
        else
            this.selectedNeighbourhood = [];
    }

    setAddressOptions = (options) => {
        this.addressOptions = options
    }

    setStateValidationMethod = (m) => {
        this.stateValidation = m;
    }

    setCityValidationMethod = (m) => {
        this.cityValidation = m;
    }

    setLocalityValidationMethod = (m) => {
        this.localityValidation = m;
    }

    setNeighbourhoodValidationMethod = (m) => {
        this.neighbourhoodValidation = m;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let states = ''
        this.selectedState.map(data => {
            console.log(data)
            states = data.value
        })
        localStorage.setItem('state', states)
        let cities = ''
        this.selectedCity.map(data => {
            console.log(data)
            cities = data.value
        })
        localStorage.setItem('city', cities)
        let localities = ''
        this.selectedLocality.map(data => {
            console.log(data)
            localities = data.value
        })
        localStorage.setItem('locality', localities)
        let neighbourhoods = ''
        this.selectedNeighbourhood.map(data => {
            console.log(data)
            neighbourhoods = data.value
        })
        localStorage.setItem('neighbourhood', neighbourhoods)
        if (this.state.selectedOption === '') {
            this.setState({ radioError: "Please Select one Account type Option." })
        } else if (this.state.checked === false) {
            this.setState({ checkError: "Please Check The Check Box to Agree Upon The Terms & Conditions and Privacy Policy." })
        } else if (this.state.age_checked === false) {
            this.setState({ ageCheckError: "Please Check The Check Box to Agree Upon The Age above 15." })
        }
        else if (validateForm(this.state.errors)) {
            const data = {
                userName: this.state.username,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                password: this.state.password,
                mobileNumber: this.state.contact_number,
                email: this.state.email_address,
                referralKey: this.state.referralKey,
                yearlyAccount: this.state.yearlyAccount,
                monthlyPrepaidAccount: this.state.monthlyPrepaidAccount,
                freeAccount: this.state.freeAccount,
                halfYearlyPrepaidAccount: this.state.halfYearlyPrepaidAccount,
                noTargetHalfYearlyAccount: this.state.noTargetHalfYearlyAccount,
                quarterlyPrepaidAccount: this.state.quarterlyPrepaidAccount,
                stateId: states,
                cityId: cities,
                localityId: localities,
                neighbourhoodId: neighbourhoods,
                hasBusiness: this.state.hasBusiness,
                canWriteContent: this.state.canWriteContent,
                canCreateVideo: this.state.canCreateVideo,
                canCreatePhoto: this.state.canCreatePhoto,
                canInfluence: this.state.canInfluence,
                canDoDigitalMarketing: this.state.canDoDigitalMarketing,
                canDoPRJournalism: this.state.canDoPRJournalism,
                // profilePhoto: this.state.profilePhoto
            }
            if (this.selectedState.length < 1) {
                this.setState({ open: true })
                notifyUser("Location", "Select Any State", type.DANGER)
            }
            else if (this.cityValidation) {
                if (this.selectedCity.length < 1) {
                    this.setState({ open: true })
                    notifyUser("Location", "Select Any City", type.DANGER)
                }
                else if (this.localityValidation) {
                    if (this.selectedLocality.length < 1) {
                        this.setState({ open: true })
                        notifyUser("Location", "Select Any Locality", type.DANGER)
                    }
                    else if (this.neighbourhoodValidation) {
                        if (this.selectedNeighbourhood.length < 1) {
                            this.setState({ open: true })
                            notifyUser("Location", "Select Any Neighbourhood", type.DANGER)
                        }
                        else {
                            this.setState({ open: false })
                            localStorage.getItem('neighbourhood', neighbourhoods)
                            axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/save', data).then(response => {
                                Loader.stopSpinner();
                                if (response.data)
                                    this.props.history.push('/activation-success/' + this.props.match.params.verify)
                                // if (response.data)
                                //     this.props.history.push({
                                //         pathname: `/otp/${response.data.id}`,
                                //         search: `?auth_mod=${data.mobileNumber}`,
                                //         state: { email: data.email }
                                //     })
                            }).catch(error => {
                                if (error.response)
                                    notifyUser("Registration", error.response.data, type.DANGER)
                            })
                        }
                    }
                    else {
                        this.setState({ open: false })
                        localStorage.getItem('locality', localities)
                        axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/save', data).then(response => {
                            Loader.stopSpinner();
                            if (response.data)
                                this.props.history.push('/activation-success/' + this.props.match.params.verify)
                            // if (response.data)
                            //     this.props.history.push({
                            //         pathname: `/otp/${response.data.id}`,
                            //         search: `?auth_mod=${data.mobileNumber}`,
                            //         state: { email: data.email }
                            //     })
                        }).catch(error => {
                            if (error.response)
                                notifyUser("Registration", error.response.data, type.DANGER)
                        })
                    }
                }
                else {
                    this.setState({ open: false })
                    localStorage.getItem('city', cities)
                    axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/save', data).then(response => {
                        Loader.stopSpinner();
                        if (response.data)
                            this.props.history.push('/activation-success/' + this.props.match.params.verify)
                        // if (response.data)
                        //     this.props.history.push({
                        //         pathname: `/otp/${response.data.id}`,
                        //         search: `?auth_mod=${data.mobileNumber}`,
                        //         state: { email: data.email }
                        //     })
                    }).catch(error => {
                        if (error.response)
                            notifyUser("Registration", error.response.data, type.DANGER, container.BOTTOM_RIGHT)
                    })
                }
            }
            else {
                this.setState({ open: false })
                localStorage.getItem('state', states)
                axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/save', data).then(response => {
                    Loader.stopSpinner();
                    if (response.data)
                        this.props.history.push('/activation-success/' + this.props.match.params.verify)
                    // if (response.data)
                    //     this.props.history.push({
                    //         pathname: `/otp/${response.data.id}`,
                    //         search: `?auth_mod=${data.mobileNumber}`,
                    //         state: { email: data.email }
                    //     })
                }).catch(error => {
                    if (error.response)
                        notifyUser("Registration", error.response.data, type.DANGER, container.BOTTOM_RIGHT)
                })
            }
        }
        // }).catch(error => {
        //     console.log("Unable to upload your profile photo")
        //     notifyUser("Profile Picture", error.response.data, type.DANGER);
        //     Loader.stopSpinner()
        // })
    }

    refKeyAvailablityTimer = null
    handleRefKey = (e) => {
        const refkey = e.target ? e.target.value : e;
        this.setState({ referralKey: refkey })
        // if (this.refKeyAvailablityTimer) clearTimeout(this.refKeyAvailablityTimer)
        // let error = { ...this.state.errors }
        // if (refkey)
        //     this.refKeyAvailablityTimer = setTimeout(() => {
        //         axios.get(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/checkrefkey?refKey=" + refkey).then(
        //             response => {
        //                 error.refKey = response.data
        //                 this.setState({ errors: error })
        //             })
        //     }, 1000);
        // else {
        //     error.refKey = ''
        //     this.setState({ errors: error })
        // }
    }

    handleUserName = (e) => {
        e.preventDefault();
        if (this.userNameAvailabilityTimer) clearTimeout(this.userNameAvailabilityTimer)
        const userName = e.target.value;
        this.setState({ username: userName })
        let errors = { ...this.state.errors }
        this.userNameAvailabilityTimer = setTimeout(async () => {
            if (userName.length > 3 && userName.length < 16)
                if (usernameRegex.test(userName)) {
                    const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/checkusername?userName=" + userName)
                    errors.username = response.data
                } else
                    errors.username = 'Username contains invalid characters';
            else
                errors.username = userName.length < 4 ? 'Username should be minimum of 4 characters' : 'Too long username';
            this.setState({ errors })
        }, 1000);
    }

    userNameAvailabilityTimer = null;
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        if (value) {
            switch (name) {
                case 'email_address': {
                    errors.email_address =
                        validEmailRegex.test(value)
                            ? ''
                            : 'Email is Not Valid!';
                    break;
                }
                case 'password': {
                    if (value.length < 8)
                        errors.password = 'Password should be minimum of 8 characters and must contain atleast 1 Lowercase , 1 Uppercase , 1 Numeric and 1 Special Character'
                    else
                        errors.password = strongPasswordRegex.test(value)
                            ? ""
                            : 'Password Must Contain at Least 1 Lowercase or 1 Uppercase , 1 Numeric and 1 Special Character';
                    errors.confirmpassword = this.state.confirmpassword && value !== this.state.confirmpassword ? 'Password Does Not Match' : ""
                    break;
                }
                case 'confirmpassword':
                    errors.confirmpassword = (value === this.state.password ? '' : 'Password Does Not Match');
                    break;
                case 'contact_number': {
                    errors.contact_number =
                        mobilenumberRegex.test(value)
                            ? (value.length < 10)
                                ? 'Mobile Number Must Have 10 Digits'
                                : ''
                            : 'Invalid Mobile Number (Hint: Do not enter STD/ISO code)'
                    break;
                }
                case 'tc': {
                    this.setState({ checked: !this.state.checked });
                    if (this.state.checked === false)
                        this.setState({ checkError: '' })
                    break;
                }
                case 'age_check': {
                    this.setState({ age_checked: !this.state.age_checked });
                    if (this.state.age_checked === false)
                        this.setState({ ageCheckError: '' })
                    break;
                }
                case 'business': {
                    this.setState({ hasBusiness: !this.state.hasBusiness });
                    break;
                }
                case 'content': {
                    this.setState({ canWriteContent: !this.state.canWriteContent });
                    break;
                }
                case 'video': {
                    this.setState({ canCreateVideo: !this.state.canCreateVideo });
                    break;
                }
                case 'photo': {
                    this.setState({ canCreatePhoto: !this.state.canCreatePhoto });
                    break;
                }
                case 'influence': {
                    this.setState({ canInfluence: !this.state.canInfluence });
                    break;
                }
                case 'marketing': {
                    this.setState({ canDoDigitalMarketing: !this.state.canDoDigitalMarketing });
                    break;
                }
                case 'journalism': {
                    this.setState({ canDoPRJournalism: !this.state.canDoPRJournalism });
                    break;
                }
                default:
                    break;
            }
            this.setState({ errors, [name]: value })
        } else {
            errors[name] = '';
        }
    }

    openModal = (type) => {
        this.setState({
            termsModel: {
                open: true,
                model_type: type,
            }
        });
    }

    closeModal = () => {
        this.setState({
            termsModel: {
                open: false,
                model_type: '',
            }
        });
    }

    profilePopup = () => {
        this.setState((prevState) => ({
            isPopupOpen: !prevState.isPopupOpen,
        }));
    };

    handleProfilePhoto = (e) => {
        const file = e.target.files[0];

        if (file && file.type.startsWith("image")) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    profilePhotoUrl: e.target.result,
                    isPopupOpen: false  // Close the popup after successful image upload
                });
            };
            reader.readAsDataURL(file);

            this.selectedImage = file;
            this.profileDataChanged = true;
        }
    }

    prepaidOffer = () => {
        axios.get(process.env.REACT_APP_BACKEND_URL + '/abmgr/orders/get-offer-status').then(response => {
            console.log(response.data)
            this.setState({ prepaidStatus: response.data.offerStatus });
            this.setState({ prepaidPercentage: response.data.percentage })
        }).catch(error => {
            console.error("Error fetching notifications:", error);
        })
    }

    handleOptionChange = (changeEvent) => {
        const value = changeEvent.target ? changeEvent.target.value : '';
        this.setState({ yearlyAccount: value === 'option1', halfYearlyPrepaidAccount: value === 'option3', quarterlyPrepaidAccount: value === 'option2', noTargetHalfYearlyAccount: value === 'option4', freeAccount: value === 'option5', monthlyPrepaidAccount: value === 'option6', selectedOption: value }, () => {
            this.setState({ radioError: this.state.selectedOption ? '' : 'Please Select one Account type Option.' })
        });
    }


    render() {

        const { errors } = this.state;

        return (
            <div className="row p-lg-4">
                <div className='col-12 col-lg-8 order-lg-1 order-3 text-left'>
                    <div className='border rounded bg-white shadow p-3'>
                        <section className="my-2">
                            <h1><b>For Businesses</b></h1>
                            <h2 style={{ color: '#FF9933' }}>All-in-One Promotional Tool</h2>
                            <p>
                                Upload your product/service long format videos in <strong>AajuBaaju My Videos</strong> segment.
                                <br /> Upload your product/service short format videos in <strong>AajuBaaju My Clips</strong> segment.
                                <br /> Upload your product/service catalogues in <strong>AajuBaaju My Photos</strong> segment.
                                <br /> Upload your product/service photos in <strong>AajuBaaju My Album</strong> segment.
                                <br /> Upload your product/service articles in <strong>AajuBaaju My Pages</strong> segment.
                                <br /> Upload your product/service reviews in <strong>AajuBaaju My Reviews</strong> segment.
                                <br /> List your business in <strong>Business Listings</strong> segment.
                                <br /> List your upcoming events in <strong>Event Listing</strong> segment.
                                <br /> List your job vacancies in <strong>Job Listings</strong> segment.
                            </p>
                            <div className="d-flex justify-content-center">
                                <img
                                    src={indianPlatform}
                                    alt="Indian Platform"
                                    className="img-fluid"
                                    style={{ width: '90%', height: "auto" }}
                                />
                            </div>
                            <h2 className="d-flex justify-content-center" style={{ fontWeight: 600 }}>Add&nbsp;<span style={{ color: '#FF9933' }}>Aaju</span><span style={{ color: '#138808' }}>Baaju</span>&nbsp;Icon to your “Follow Us On” list.</h2><br></br>
                            <h2 style={{ color: '#FF9933' }}>Complete Flexibility in Sharing</h2>
                            <ul>
                                <li className='d-flex'><span><i class='far fa-hand-point-right'></i></span>&nbsp;<span>Share Multiple Business Pages in a Single Link (<i style={{ color: '#138808' }}>Unique Feature</i>) OR</span></li>
                                <li className='d-flex'><span><i class='far fa-hand-point-right'></i></span>&nbsp;<span>Share a Single Business Page OR</span></li>
                                <li className='d-flex'><span><i class='far fa-hand-point-right'></i></span>&nbsp;<span>Share a Specific Segment Like Videos/Catalogue/Pages OR</span></li>
                                <li className='d-flex'><span><i class='far fa-hand-point-right'></i></span>&nbsp;<span>Share a Single Video/Catalogue/Page</span></li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <div className={`text-center ${styles.regis_content_border}`}>
                                    <h1>
                                        <span style={{ color: '#FF9933' }}>Spend No More;</span><br></br>
                                        <span style={{ color: '#138808' }}>Just Earn More!</span>
                                    </h1>
                                    <h2 className="responsive-text" style={{ color: '#000080' }}>
                                        Getting Business from the Promotions is <b>NORMAL</b><br></br>
                                        Getting Business + <b>Earning</b> Money <b>for Promoting</b> is <b>NEW</b>
                                    </h2>
                                </div>
                            </div>
                        </section>
                        <br></br>
                        <hr className="dotted" style={{ borderTop: "8px dotted black", width: '30%' }}></hr>
                        <br></br>
                        {/* For Online Advertisers Section */}
                        <section className="my-2">
                            <h1><b>For Online Advertisers</b></h1>
                            <h2 style={{ color: '#FF9933' }}>Transforming the Online Advertisement Industry</h2>
                            <ul>
                                <li className='d-flex'><span><i class='far fa-hand-point-right'></i></span>&nbsp;<span>Before the rise of online advertising, marketing efforts focused primarily on brand awareness.</span></li>
                                <li className='d-flex'><span><i class='far fa-hand-point-right'></i></span>&nbsp;<span>With the growth of online ads, target marketing became more prominent.</span></li>
                                <li className='d-flex'><span><i class='far fa-hand-point-right'></i></span>&nbsp;<span>Now, aajubaaju.in is revolutionizing online advertising by using it as a tool to boost purchasing power by turning users into potential buyers.</span></li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <div className={`text-center ${styles.regis_content_border}`}>
                                    <h2 className="responsive-text" style={{ color: '#000080' }}>
                                        The existing online platforms give the opportunity <br></br> for the advertisers to
                                        <strong style={{ textDecoration: 'underline' }}> TARGET</strong> prospective buyers.
                                    </h2>
                                    <h2><strong style={{ fontSize: '24px' }}>
                                        <span style={{ color: '#FF9933' }}>aaju</span>
                                        <span style={{ color: '#138808' }}>baaju</span>
                                        <span style={{ color: '#000080' }}>.in</span>
                                    </strong>
                                        <br />
                                        gives the opportunity for the advertisers <br></br> to
                                        <strong style={{ textDecoration: 'underline' }}> CREATE</strong> their prospective buyers.
                                    </h2>
                                </div>
                            </div>

                        </section>
                        <br></br>
                        <hr className="dotted" style={{ borderTop: "8px dotted black", width: '30%' }}></hr>
                        <br></br>
                        {/* For Content Creators Section */}
                        <section className="my-2">
                            <h1><b>For Content Creators</b></h1>
                            <h2 style={{ color: '#FF9933' }}>All-in-One Platform for your Multiple Skills</h2>
                            <br></br>
                            <div className="d-flex justify-content-center">
                                <div className='text-center'>
                                    <h2 className="responsive-text" style={{ color: '#000080' }}>
                                        Earn Ad Revenue Share from the Advertisements <br></br>displayed in your Content Page.
                                    </h2>
                                </div>
                            </div><br></br>
                            <ul>
                                <li><strong>AajuBaaju My Videos</strong> segment for long format videos.</li>
                                <li><strong>AajuBaaju My Clips</strong> segment for short format videos.</li>
                                <li><strong>AajuBaaju My Photos</strong> segment for informational photo stories.</li>
                                <li><strong>AajuBaaju My Album</strong> segment for personal photo stories.</li>
                                <li><strong>AajuBaaju My Pages</strong> segment for blogging.</li>
                                <li><strong>AajuBaaju My Reviews</strong> segment for review writing</li>
                                <li><strong>Business, Event Listing, Job Listings</strong> segments to build your own online directory.</li>
                            </ul>
                        </section>
                        <br></br>
                        <hr className="dotted" style={{ borderTop: "8px dotted black", width: '30%' }}></hr>
                        <br></br>
                        {/* For Digital Entrepreneurship Section */}
                        <section className="my-2">
                            <h1><b>For Digital Entrepreneurship</b></h1>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="form-group row w-100 d-flex justify-content-center">
                                    <div className="col-12 col-md-5 text-left p-0">
                                        <div className='col-12'>
                                            <img
                                                src={employmentImg}
                                                alt="Employment"
                                                className="img-fluid"
                                                style={{ width: '100%', height: "auto" }}
                                            />
                                        </div>
                                        <div className='col-12'>
                                            <img
                                                src={entrepreneurshipImg}
                                                alt="Entrepreneurship"
                                                className="img-fluid"
                                                style={{ width: '100%', height: "auto" }}
                                            />
                                        </div>
                                        <div className='col-12'>
                                            <img
                                                src={digitalImg}
                                                alt="Digital"
                                                className="img-fluid"
                                                style={{ width: '100%', height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-7 d-flex">
                                        <div className="row w-100 align-items-center">
                                            <div className="col-12 col-md-9 text-left">
                                                <h2 style={{ color: '#FF9933' }}>Run Your Own Online Businesses</h2>
                                                <p>
                                                    Video Streaming Website like Youtube<br></br>
                                                    Photo Sharing Website like Instagram<br></br>
                                                    Classifieds Website like Just Dial<br></br>
                                                    Blogging Website like Blogger<br></br>
                                                    Job Posting Website like Times Jobs<br></br>
                                                    Event Posting Website like Book My Show<br></br>
                                                    Review Posting Website like Google Reviews<br></br>
                                                    Online Advertising like Google Adsense.<br></br>
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-3 text-center d-flex align-items-center justify-content-center">
                                                <h2 style={{ color: '#000080' }}>Democratizing<br />Digital<br />Entrepreneurship</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="d-flex justify-content-center">
                            <img
                                src={aajubaajuImg}
                                alt="Aajubaaju.in"
                                className="img-fluid"
                                style={{ width: '100%', height: "auto" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4 order-lg-2 order-1">
                    <TermsAndConditions closeModal={this.closeModal} terms={this.state.termsModel} />
                    <form onSubmit={this.handleSubmit} className='border rounded bg-white shadow p-2'>
                        <Link to="/">
                            <img
                                src={ablogo}
                                alt="AajuBaaju Logo"
                                className="img-fluid"
                                style={{ width: "100%", height: "auto" }}
                            />
                        </Link>
                        <h2 className="text-center mb-6">Registration Form</h2>

                        <div className="form-group row col-12 text-left">
                            <h2>About Me</h2>
                            <div className="row form-row col-md-12">
                                <p>Please tick &#9989; all the applicable boxes.</p>
                                <label className="col-12 col-md-12 col-form-label">
                                    <input type="checkbox" className={`${styles.form_check_input}`} name="business" hasBusiness={this.state.hasBusiness}
                                        onInput={this.handleChange} noValidate readOnly />I have business/businesses
                                </label><br></br>
                            </div>
                            <div className="row form-row col-md-12">
                                <label className="col-12 col-md-12 col-form-label">
                                    <input type="checkbox" className={`${styles.form_check_input}`} name="content" canWriteContent={this.state.canWriteContent}
                                        onInput={this.handleChange} noValidate readOnly />I can write content in English
                                </label>
                            </div>
                            <div className="row form-row col-md-12">
                                <label className="col-12 col-md-12 col-form-label">
                                    <input type="checkbox" className={`${styles.form_check_input}`} name="video" canCreateVideo={this.state.canCreateVideo}
                                        onInput={this.handleChange} noValidate readOnly />I can create video content
                                </label>
                            </div>
                            <div className="row form-row col-md-12">
                                <label className="col-12 col-md-12 col-form-label">
                                    <input type="checkbox" className={`${styles.form_check_input}`} name="photo" canCreatePhoto={this.state.canCreatePhoto}
                                        onInput={this.handleChange} noValidate readOnly />I can create photo content
                                </label>
                            </div>
                            <div className="row form-row col-md-12">
                                <label className="col-12 col-md-12 col-form-label">
                                    <input type="checkbox" className={`${styles.form_check_input}`} name="influence" canInfluence={this.state.canInfluence}
                                        onInput={this.handleChange} noValidate readOnly />I can influence people
                                </label>
                            </div>
                            <div className="row form-row col-md-12">
                                <label className="col-12 col-md-12 col-form-label">
                                    <input type="checkbox" className={`${styles.form_check_input}`} name="marketing" canDoDigitalMarketing={this.state.canDoDigitalMarketing}
                                        onInput={this.handleChange} noValidate readOnly />I can do digital marketing
                                </label>
                            </div>
                            <div className="row form-row col-md-12">
                                <label className="col-12 col-md-12 col-form-label">
                                    <input type="checkbox" className={`${styles.form_check_input}`} name="journalism" canDoPRJournalism={this.state.canDoPRJournalism}
                                        onInput={this.handleChange} noValidate readOnly />I can do PR/Journalism
                                </label>
                            </div>
                        </div>
                        <div className="row col-12 text-left">
                            <h2>Choose Your Subscription</h2>
                            <div className="row form-row col-12 col-md-12">
                                <label className='container_radio col-12 col-md-12 d-flex'>
                                    <div className='col-1 col-md-1'><input name="account_type" type="radio" value="option6" checked={this.state.selectedOption === 'option6'} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                        <span className="checkmark"></span></div>

                                    <div className='col-6 col-md-9 p-0'><span className='text-left word-break'>Monthly*** Subscription - Rs. 1500/-*</span></div>
                                    {/* <img src={monthlyOffer} alt="Monthly Offer" className="img-fluid" width='90px' height='auto' /> */}
                                </label>
                            </div>
                            <div className="row form-row col-12 col-md-12">
                                <label className='container_radio col-12 col-md-12 d-flex'>
                                    {/* Rs.3000/-* <img className='img-fluid' width='80px' src={limited} /> */}
                                    <div className='col-1 col-md-1'><input name="account_type" type="radio" value="option4" checked={this.state.selectedOption === 'option4'} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                        <span className="checkmark"></span></div>

                                    <div className='col-11 col-md-11 p-0'><span className='text-left word-break'>Half Year** Subscription - Rs.4500/-*</span></div>
                                </label>
                            </div>
                            <div className="row form-row col-md-12">
                                <label className='container_radio col-12 col-md-12 d-flex'>
                                    {/* Rs.6000/-* <strong>(Freedom Offer till 15 Aug)</strong> */}
                                    <div className='col-1 col-md-1'><input name="account_type" type="radio" value="option1" checked={this.state.selectedOption === 'option1'} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                        <span className="checkmark"></span></div>

                                    <div className='col-11 col-md-11 p-0'><span className='text-left'>Annual Subscription - Rs.9000/-* </span></div>
                                </label>
                            </div>
                            <div className="row form-row col-md-12">
                                <label className='container_radio col-12 col-md-12 d-flex'>
                                    <div className='col-1 col-md-1'><input name="account_type" type="radio" value="option5" checked={this.state.selectedOption === 'option5'} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                        <span className="checkmark"></span></div>

                                    <div className='col-11 col-md-11 p-0'><span className='text-left'>Postpaid Annual Subscription <br></br>Accumulate and Pay</span></div>
                                </label>
                            </div>

                            <label className={`col-12 col-md-12 ${styles.reg_form_section_label}`}>&nbsp;&nbsp;*( + 18% GST)
                                <br></br>&nbsp; **Renewal will be in Annual Subscription.
                                <br></br>&nbsp; ***Renewal will be in Monthly or Annual.
                                {/* <br></br>&nbsp; Note: The withdrawal option will not be available during Quarter Year Subscription Period. */}
                            </label>
                            <div className={`${styles.error}`}>{this.state.radioError}</div>
                        </div>
                        <div className="form-group row col-12 text-left">
                            <div className="col-12 col-md-4">
                                <label className={`${styles.reg_form_section_label} col-form-label`} htmlFor="title">Name*</label>
                            </div>
                            <div className="col-12 col-md-8 pb-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    name="firstName"
                                    required
                                    value={this.state.firstName}
                                    noValidate
                                    onChange={(event) => this.setState({ firstName: event.target.value })}
                                />
                            </div>
                            <div className="col-12 col-md-4"></div>
                            <div className="col-12 col-md-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    name="lastName"
                                    required
                                    value={this.state.lastName}
                                    noValidate
                                    onChange={(event) => this.setState({ lastName: event.target.value })}
                                />
                            </div>
                        </div>

                        <div className="form-group row col-12 text-left">
                            <div className="col-12 col-md-4">
                                <label className={`${styles.reg_form_section_label}`} htmlFor="title">User name*</label>
                            </div>
                            <div className="col-12 col-md-8">
                                <input type="text" className="form-control" placeholder="User name"
                                    name="username" required value={this.state.username} noValidate autoComplete="new-password"
                                    onChange={this.handleUserName}
                                />
                                <span className={`${styles.error}`}>{errors.username}</span>
                            </div>
                        </div>
                        <div className="form-group row col-12 text-left">
                            <div className="col-12 col-md-4">
                                <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Password*</label>
                            </div>
                            <div className="col-12 col-md-8">
                                <input type="password" className="form-control" placeholder="Password"
                                    name="password" autoComplete="new-password" required
                                    value={this.state.password} noValidate
                                    onChange={(event) => { this.setState({ password: event.target.value }); this.handleChange(event) }}
                                />
                                <span className={`${styles.error}`}>{errors.password}</span>
                            </div>
                        </div>
                        <div className="form-group row col-12 text-left">
                            <div className="col-12 col-md-4">
                                <label className={`${styles.reg_form_section_label}`} htmlFor="title">Confirm password*</label></div>
                            <div className="col-12 col-md-8">
                                <input type="password" className="form-control" placeholder="Confirm password"
                                    name="confirmpassword" required value={this.state.confirmpassword} noValidate
                                    onChange={(event) => { this.setState({ confirmpassword: event.target.value }); this.handleChange(event) }}
                                />
                                <span className={`${styles.error}`}>{errors.confirmpassword}</span>
                            </div>
                        </div>
                        <div className="form-group row col-12 text-left">
                            <div className="col-12 col-md-4">
                                <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Mobile number*</label>
                            </div>
                            <div className="col-12 col-md-8">
                                <input className="form-control" placeholder="Mobile number" noValidate maxLength="10"
                                    name="contact_number" required value={this.state.contact_number}
                                    onChange={(event) => { this.setState({ contact_number: event.target.value }); this.handleChange(event) }}
                                />
                                <span className={`${styles.error}`}>{errors.contact_number}</span>
                            </div>
                        </div>
                        <div className="form-group row col-12 text-left">
                            <div className="col-12 col-md-4">
                                <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Email address*</label>
                            </div>
                            <div className="col-12 col-md-8">
                                <input className="form-control" placeholder="Email address" name="email_address"
                                    required value={this.state.email_address} noValidate
                                    onChange={(event) => { this.setState({ email_address: event.target.value }); this.handleChange(event) }}
                                />
                                <span className={`${styles.error}`}>{errors.email_address}</span>
                            </div>
                        </div>
                        <div className="form-group row col-12 text-left">
                            <div className="col-12 col-md-4">
                                <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Invitation key</label></div>
                            <div className="col-12 col-md-8">
                                {(window.location.href === window.location.origin + '/registration?ref=' + this.state.referralKey) ? <input className="form-control" placeholder="Enter invitation key"
                                    name="referralKey" value={this.state.referralKey} noValidate
                                    onChange={this.handleRefKey} readOnly
                                /> :
                                    <input className="form-control" placeholder="Enter invitation key"
                                        name="referralKey" value={this.state.referralKey} noValidate
                                        onChange={this.handleRefKey}
                                    />}
                                <span className={`${styles.error}`}>{errors.refKey}</span>
                            </div>
                        </div>
                        <div className="form-group row col-12 text-left">
                            <div className="col-12 col-md-4">
                                <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Location*</label>
                            </div>
                            <div className="col-12 col-md-8">
                                <SetAdvertiseLocation
                                    //heading='Areas Served' isMulti
                                    setFilterValidationMethod={this.setLocationValidationMethod}
                                    allLocations={this.state.fullAddress}
                                    selectedStates={this.state.selectedState}
                                    selectedCities={this.state.selectedCity}
                                    selectedLocalities={this.state.selectedLocality}
                                    selectedNeighbourhoods={this.state.selectedNeighbourhood}
                                    _handleState={this._handleState}
                                    _handleCity={this._handleCity}
                                    _handleLocality={this._handleLocality}
                                    _handleNeighbourhood={this._handleNeighbourhood}
                                    addressOptions
                                    setAddressOptions={this.setAddressOptions}
                                    labelClass={'col-md-8'}
                                    setStateValidationMethod={this.setStateValidationMethod}
                                    setCityValidationMethod={this.setCityValidationMethod}
                                    setLocalityValidationMethod={this.setLocalityValidationMethod}
                                    setNeighbourhoodValidationMethod={this.setNeighbourhoodValidationMethod}
                                />
                                <p className='location_p'>Tell us your location to see what is near you. (If your location is not available, please select your nearest location.)</p>
                            </div>
                        </div>
                        <div className="form-group row col-12 text-left">
                            <div className={`${styles.form_check} col-12 col-md-12`}>
                                <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                    <input type="checkbox" className={`${styles.form_check_input}`} name="age_check" age_checked={this.state.age_checked}
                                        onInput={this.handleChange} noValidate readOnly />I am above 15 Years of Age & Have a PAN.
                                </label>
                                <div className={`${styles.error}`}>{this.state.ageCheckError}</div>
                            </div>
                        </div>
                        <div className="form-group row col-12 text-left">
                            <div className={`${styles.form_check} col-12 col-md-12`}>
                                <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                    <input type="checkbox" className={`${styles.form_check_input}`} name="tc" checked={this.state.checked}
                                        onInput={this.handleChange} noValidate readOnly />I have read and understood the &nbsp;
                                    <button type='button' onClick={() => this.openModal('terms')}
                                        className={`btn ${styles.btn_link}`}>Terms &amp; Conditions,</button> &nbsp;
                                    <button type='button' onClick={() => this.openModal('privacy')}
                                        className={`btn ${styles.btn_link}`}>Privacy Terms,</button>&nbsp;
                                    <button type='button' onClick={() => this.openModal('refund')}
                                        className={`btn ${styles.btn_link}`}>Refund Policy</button> and &nbsp;
                                    <button type='button' onClick={() => this.openModal('works')}
                                        className={`btn ${styles.btn_link}`}>How It Works?</button> and agree.
                                </label>
                                <div className={`${styles.error}`}>{this.state.checkError}</div>
                            </div>
                        </div>
                        {/* </div> */}
                        <div className="form-group row justify-content-center">
                            <div className="col-12 text-center">
                                <ButtonStyle type="submit" className="btn" text={'Submit'} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12 text-center">
                                <Link to="/login/user">Do you already have an account?<br></br>LOGIN</Link>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="form-group row">
                            <div className="col-12 text-center">
                                <Link to="/contact">Contact Support</Link> &nbsp; | &nbsp; <Link to="/">Back to Home</Link>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='col-12 order-2 col-lg-3 d-block d-md-none' style={{ paddingTop: '2.5%' }}></div>
            </div>
        )
    }
}

export default Registration;