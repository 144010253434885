import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { validEmailRegex, mobilenumberRegex, ifscRegex, validateForm, pan, gstNumber } from '../RegxConst'
import { Form, Button, Table } from 'react-bootstrap';
import { TitleHeading } from '../UIElements/headings/TitleHeading';
import styles from '../listings/forms/addpost.module.css';
import style from '../UIElements/Form_label/FormLabels.module.css'
import BillBoard from '../advertisements/BillBoard';
import LargeRectangle from '../advertisements/LargeRectangle';
import { notifyUser, type } from '../utility/notifyUser';
import SetLocation from '../SetFilters/SetLocation/SetLocation';
import authservice from '../Authentication/service/authservice';
import axios from 'axios';
import SetCategory from '../SetFilters/SetCategory/SetCategory';
import WorkingHours from '../listings/WorkingHours';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';

const ManageMart = (props) => {
    const [formVisible, setFormVisible] = useState(false);
    const [showApprovalPopup, setShowApprovalPopup] = useState(false);
    const [thumbnailImage, setThumbnailImage] = useState(null);
    const [panImage, setPanImage] = useState(null);
    const [isThumbnailPicValid, setIsThumbnailPicValid] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        id: null,
        userId: authservice.getCurrentUserid(),
        martName: '',
        businessDescription: '',
        thumbnailImage: null,
        thumbnailDescription: '',
        businessAddress: '',
        deletedAddresses: '',
        contactName: '',
        contactNumber: '',
        certifications: [],
        homeDeliveryAvailable: false,
        pickupAvailable: false,
        open: '',
        workingHours: [],
        categories: [],
        subCategories: [],
        country: [],
        states: [],
        cities: [],
        localities: [],
        neighbourhoods: [],
        bankName: '',
        accountHolderName: '',
        bankAccountNumber: '',
        ifsc: '',
        panNum: '',
        panImage: null,
        certificateImage: null,
        panLinkWithAadhar: false,
        gstNumber: '',
        lattitude: null,
        longitude: null,
    });
    const [marts, setMarts] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [categoryValidation, setCategoryValidation] = useState(null);
    const [subCategoryValidation, setSubCategoryValidation] = useState(null);
    const [allLocations, setAllLocations] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedLocality, setSelectedLocality] = useState(null);
    const [selectedNeighbourhood, setSelectedNeighbourhood] = useState(null);
    const [bankAccountNumber, setBankAccountNumber] = useState("");
    const bankAvailabilityTimer = useRef(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        const userId = authservice.getCurrentUserid(); // Replace with dynamic user ID if necessary
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/all/${userId}`)
            .then((response) => {
                if (response.status === 200) {
                    setMarts(response.data); // Populate the marts state with API data

                } else {
                    alert("Failed to fetch mart data.");
                }
            })
            .catch((error) => {
                console.error("Error fetching mart data:", error);
            })
            .finally(() => setLoading(false));
    }, []);


    const [workingHours, setWorkingHours] = useState({
        monday_open: "", monday_close: "",
        tuesday_open: "", tuesday_close: "",
        wednesday_open: "", wednesday_close: "",
        thursday_open: "", thursday_close: "",
        friday_open: "", friday_close: "",
        saturday_open: "", saturday_close: "",
        sunday_open: "", sunday_close: ""
    });


    const handleTimeChange = (timeString, day, type) => {
        setWorkingHours((prev) => ({
            ...prev,
            [`${day}_${type}`]: timeString
        }));

        if (type === 'close' && workingHours[`${day}_open`]) {
            const fromTime = moment(workingHours[`${day}_open`], 'h:mm a');
            const toTime = moment(timeString, 'h:mm a');
            if (!toTime.isAfter(fromTime)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [day]: 'Closing time must be after opening time'
                }));
            } else {
                setErrors((prevErrors) => {
                    const newErrors = { ...prevErrors };
                    delete newErrors[day];
                    return newErrors;
                });
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleRadioInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value === "true", // Convert string to boolean
        }));
    };


    const handleThumbnailChange = async (event) => {
        const file = event.target.files[0];

        if (file && file.type.startsWith("image")) {
            const reader = new FileReader();
            reader.onload = () => {
                setThumbnailImage(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append("photo", file);

            axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-profile-photo`, formData).then(response => {
                if (response.status === 200) {
                    const uploadedFileName = response.data;
                    setFormData(prev => ({
                        ...prev,
                        thumbnailImage: uploadedFileName,
                    }));
                    setIsThumbnailPicValid(true);
                } else {
                    setIsThumbnailPicValid(false);
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        thumbnailImage: "Please upload a square image with minimum 425px x 425px.",
                    }));
                    notifyUser(
                        "Error",
                        "Please upload a square image with minimum 425px x 425px.",
                        "error"
                    );
                }
            })
                .catch(error => {
                    setIsThumbnailPicValid(false);
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        thumbnailImage: "Please upload a square image with minimum 425px x 425px.",
                    }));
                });
        } else {
            setIsThumbnailPicValid(false);
            setErrors((prevErrors) => ({
                ...prevErrors,
                thumbnailImage: "Please select a valid image file",
            }));
            notifyUser("Invalid File", "Please select a valid image file", "warning");
        }
    };

    const handlePanChange = async (event) => {
        const file = event.target.files[0];

        if (file && file.type.startsWith("image")) {
            const reader = new FileReader();
            reader.onload = () => {
                setPanImage(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append("photo", file);

            axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-pan-card`, formData).then(response => {
                if (response.status === 200) {
                    const uploadedFileName = response.data;
                    setFormData(prev => ({
                        ...prev,
                        panImage: uploadedFileName,
                    }));
                }
            }).catch(error => {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    panImage: "Please upload Pan Image.",
                }));
            });
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                panImage: "Please select a valid image file",
            }));
            notifyUser("Invalid File", "Please select a valid image file", "warning");
        }
    };

    const handleCertificateChange = async (index, field, file) => {
        if (file && file.type.startsWith("image")) {
            const formData = new FormData();
            formData.append("photo", file);

            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-pan-card`,
                    formData
                );

                if (response.status === 200) {
                    handleCertifications(index, field, response.data);
                } else {
                    notifyUser("Error", "Failed to upload certificate image", "error");
                }
            } catch (error) {
                notifyUser("Error", "Error uploading certificate image", "error");
            }
        }
    };


    const handleCertifications = (index, field, value) => {
        const updatedCertifications = [...formData.certifications];
        updatedCertifications[index] = {
            ...updatedCertifications[index],
            [field]: value,
        };
        setFormData({ ...formData, certifications: updatedCertifications });
    };


    const addCertificationField = () => {
        setFormData({
            ...formData,
            certifications: [...formData.certifications, { name: '', image: null }],
        });
    };

    const handleSubmit = async () => {

        const formattedData = Object.keys(workingHours).reduce((acc, key) => {
            const [day, type] = key.split('_');
            if (!acc[day]) acc[day] = { day };
            acc[day][type === 'open' ? 'openingTime' : 'closingTime'] = workingHours[key] || null;
            return acc;
        }, {});

        const finalData = Object.values(formattedData);

        const isFormValid = validateFormValues(formData);
        const thumbnailImage = formData.thumbnailImage
            ? formData.thumbnailImage.split("/").pop()
            : formData.thumbnailImage;
        const panImage = formData.panImage
            ? formData.panImage.split("/").pop()
            : formData.panImage;
        const certificateImage = formData.certificateImage
            ? formData.panImage.split("/").pop()
            : formData.certificateImage;
        if (isFormValid) {
            const martDTO = {
                id: null,
                userId: authservice.getCurrentUserid(),
                name: formData.martName,
                businessDescription: formData.businessDescription,
                thumbnailImage: thumbnailImage,
                thumbnailDescription: formData.thumbnailDescription,
                businessAddress: formData.businessAddress,
                contactName: formData.contactName,
                contactNumber: formData.contactNumber,
                // certifications: [],
                certifications: formData.certifications.map(cert => ({
                    name: cert.name,
                    image: cert.image,
                })),
                homeDeliveryAvailable: formData.homeDeliveryAvailable,
                pickupAvailable: formData.pickupAvailable,
                workingHours: finalData,
                categories: formData.categories,
                subCategories: formData.subCategories,
                country: 'India',
                states: formData.states,
                cities: formData.cities,
                localities: formData.localities,
                neighbourhoods: formData.neighbourhoods,
                bankName: formData.bankName,
                accountHolderName: formData.accountHolderName,
                bankAccountNumber: formData.bankAccountNumber,
                ifsc: formData.ifsc,
                pan: formData.panNum,
                panImage: panImage,
                panLinkWithAadhar: formData.panLinkWithAadhar,
                gstNumber: formData.gstNumber,
                lattitude: null,
                longitude: null

            };

            axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/save`, martDTO)
                .then((response) => {
                    if (response.status === 200) {
                        notifyUser("Success", "Mart added successfully", "success");
                        setMarts([...marts, { ...formData }]);
                    } else {
                        notifyUser("Error", response.data, type.DANGER)
                    }
                })
                .catch((error) => {
                    console.error("Error adding mart:", error);
                    notifyUser("Error", "Error adding mart", "danger")
                });
        }
    }

    const handleAccount = (e) => {
        if (bankAvailabilityTimer.current) clearTimeout(bankAvailabilityTimer.current);

        const updatedBankAccountNumber = e.target.value.toUpperCase();
        setBankAccountNumber(updatedBankAccountNumber);

        bankAvailabilityTimer.current = setTimeout(async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/abmgr/auth/check-bank-account-number?bankAccountNumber=${updatedBankAccountNumber}`
                );
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    bankAccountNumber: response.data,
                }));
            } catch (error) {
                console.error("Error checking bank account number:", error);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    bankAccountNumber: "Unable to verify account number",
                }));
            }
        }, 1000);
    };

    // Clean up the timer on component unmount
    useEffect(() => {
        return () => {
            if (bankAvailabilityTimer.current) clearTimeout(bankAvailabilityTimer.current);
        };
    }, []);


    const validateFormValues = (values) => {
        const errors = {};
        let isValid = true;

        const isEmpty = (value) => !value;
        const isValidPhone = (contactNumber) => mobilenumberRegex.test(contactNumber);
        const isValidIFSC = (ifsc) => ifscRegex.test(ifsc);
        const isValidPAN = (panNum) => pan.test(panNum);
        const isValidGST = (gst) => gstNumber.test(gst);

        // Required field validations
        if (isEmpty(values.martName)) {
            isValid = false;
            errors.martName = 'Mart name is required';
        }
        if (isEmpty(values.businessDescription)) {
            isValid = false;
            errors.businessDescription = 'Business description is required';
        }
        if (!thumbnailImage) {
            isValid = false;
            errors.thumbnailImage = 'Thumbnail Image is required';
        }
        if (isEmpty(values.thumbnailDescription)) {
            isValid = false;
            errors.thumbnailDescription = 'Thumbnail description is required';
        }
        if (isEmpty(values.businessAddress)) {
            isValid = false;
            errors.businessAddress = 'Business address is required';
        }
        if (isEmpty(values.contactName)) {
            isValid = false;
            errors.contactName = 'Contact name is required';
        }
        if (!isValidPhone(values.contactNumber)) {
            isValid = false;
            errors.contactNumber = 'Valid phone number is required';
        }
        if (isEmpty(values.subCategories)) {
            isValid = false;
            errors.subCategories = 'At least one subcategory is required';
        }
        if (isEmpty(values.bankName)) {
            isValid = false;
            errors.bankName = 'Bank name is required';
        }
        if (isEmpty(values.accountHolderName)) {
            isValid = false;
            errors.accountHolderName = 'Account holder name is required';
        }
        if (isEmpty(values.bankAccountNumber)) {
            isValid = false;
            errors.bankAccountNumber = 'Bank account number is required';
        }
        if (!isValidIFSC(values.ifsc)) {
            isValid = false;
            errors.ifsc = 'Valid IFSC code is required';
        }
        if (!isValidPAN(values.panNum)) {
            isValid = false;
            errors.panNum = 'Valid PAN is required';
        }
        if (!panImage) {
            isValid = false;
            errors.panNum = 'Valid PAN is required';
        }

        // Set errors and return overall validity
        setErrors(errors);
        return isValid;
    };

    useEffect(() => {
        const fetchAllCategories = async () => {
            try {
                let userId = authservice.getCurrentUser().id;
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/abmgr/category/get-mart-subcategories/${userId}`
                );

                const categoriesData = response.data;
                console.log(categoriesData)
                setAllCategories(categoriesData);

                if (categoriesData.length > 0) {
                    setFormData((prev) => ({
                        ...prev,
                        categories: [categoriesData[0].categoryId],
                    }));
                }
                if (props.fullCategory) {
                    props.fullCategory(categoriesData);
                }
            } catch (error) {
                console.log(error, 'Getting data failed');
            }
        };

        fetchAllCategories();
    }, [props]);

    const showCategory = true;

    const handleNewCategory = (data) => {
        setAllCategories((prevCategories) => [...prevCategories, data]);
    };

    const handleCategoryChange = (selectedCategories) => {
        const categoriesArray = selectedCategories.map(category => category.value);
        setFormData(prev => ({
            ...prev,
            categories: categoriesArray
        }));
    };

    const handleSubCategoryChange = (selectedSubCategories) => {
        const subCategoriesArray = selectedSubCategories.map(subCategory => subCategory.value);
        setFormData(prev => ({
            ...prev,
            subCategories: subCategoriesArray
        }));
    };

    const handleSetCategoryValidationMethod = (m) => {
        setCategoryValidation(m);
    };

    const handleSetSubCategoryValidationMethod = (m) => {
        setSubCategoryValidation(m);
    };

    useEffect(() => {
        const fetchAllLocations = async () => {
            try {
                const [getFullAddress] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getallstates`),
                ]);
                setAllLocations(getFullAddress.data);
                if (props.fullAddress) {
                    props.fullAddress(getFullAddress.data);
                }
            } catch (error) {
                console.log(error, 'Getting data failed');
            }
        };

        fetchAllLocations();
    }, [props]);

    const handleNewLocation = (data) => {
        setAllLocations((prevLocations) => [...prevLocations, data]);
    };

    const handleStateChange = (selectedStates) => {
        const statesArray = selectedStates.map(state => state.value);
        setFormData(prev => ({
            ...prev,
            states: statesArray
        }));
    };

    const handleCityChange = (selectedCities) => {
        const citiesArray = selectedCities.map(city => city.value);
        setFormData(prev => ({
            ...prev,
            cities: citiesArray
        }));
    };

    const handleLocalityChange = (selectedLocalities) => {
        const localitiesArray = selectedLocalities.map(locality => locality.value);
        setFormData(prev => ({
            ...prev,
            localities: localitiesArray
        }));
    };

    const handleNeighbourhoodChange = (selectedNeighbourhoods) => {
        const neighbourhoodArray = selectedNeighbourhoods.map(neighbourhood => neighbourhood.value);
        setFormData(prev => ({
            ...prev,
            neighbourhoods: neighbourhoodArray
        }));
    };

    const handleEditMart = (martId) => {
        history.push(`/edit-mart/${martId}`);
    };

    const handleAddMartClick = async () => {
        const userId = authservice.getCurrentUserid();
        try {
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `/abmgr/user/get-content-approval-status/${userId}`);

            if (response.data.approvalFrMart === true) {
                setFormVisible(true);
            } else {
                setShowApprovalPopup(true);
            }
        } catch (error) {
            console.error("Error fetching mart subcategories:", error);
            setShowApprovalPopup(true);
        }
    };


    return (
        <div className="admin-view-head">
            <div className="mt-4 g-2 col-md-12">
                <TitleHeading text='Mart Management' />
                <Button
                    variant="primary"
                    className="mb-3 secondary"
                    onClick={handleAddMartClick}>
                    Add Mart
                </Button>
                {formVisible && (
                    <div>
                        {/* <div className="popup-content"> */}
                        <Button variant="danger" className="popup-close-btn" onClick={() => setFormVisible(false)}>
                            &times;
                        </Button>
                        <Form>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Mart Name</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="martName"
                                            value={formData.martName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.martName && <p style={{ color: "red" }}>{errors.martName}</p>}
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Business Description</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            as="textarea"
                                            name="businessDescription"
                                            value={formData.businessDescription}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.businessDescription && <p style={{ color: "red" }}>{errors.businessDescription}</p>}
                                    </div>
                                </div>
                            </Form.Group>
                            <SetCategory
                                showCategory={showCategory}
                                setCategoryValidationMethod={handleSetCategoryValidationMethod}
                                setSubCategoryValidationMethod={handleSetSubCategoryValidationMethod}
                                //heading="Set Category"
                                isMulti
                                allCategories={allCategories}
                                _handleNewCategory={handleNewCategory}
                                newFilterForm={true}
                                selectedCategories={formData.categories}
                                selectedSubCategories={[selectedSubCategories]}
                                _handleCategory={handleCategoryChange}
                                _handleSubCategory={handleSubCategoryChange}
                                wrapperClass="col-md-12"
                                labelClass="col-md-2"
                            />

                            <SetLocation
                                heading="Area Served"
                                isMulti
                                allLocations={allLocations}
                                _handleNewLocation={handleNewLocation}
                                newFilterForm={true}
                                //disabled={disable}
                                selectedStates={[selectedState]}
                                selectedCities={[selectedCity]}
                                selectedLocalities={[selectedLocality]}
                                selectedNeighbourhoods={[selectedNeighbourhood]}
                                _handleState={handleStateChange}
                                _handleCity={handleCityChange}
                                _handleLocality={handleLocalityChange}
                                _handleNeighbourhood={handleNeighbourhoodChange}
                                wrapperClass="col-md-12"
                                labelClass="col-md-2"
                            />

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Thumbnail Image</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="file"
                                            name="thumbnailImage"
                                            onChange={handleThumbnailChange}
                                            accept="image/*"
                                        />
                                        {errors.thumbnailImage && <p style={{ color: "red" }}>{errors.thumbnailImage}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Thumbnail Description</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            as="textarea"
                                            name="thumbnailDescription"
                                            value={formData.thumbnailDescription}
                                            onChange={handleInputChange}
                                        />
                                        {errors.thumbnailDescription && <p style={{ color: "red" }}>{errors.thumbnailDescription}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Business Address</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type='text'
                                            name="businessAddress"
                                            value={formData.businessAddress}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.businessAddress && <p style={{ color: "red" }}>{errors.businessAddress}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <div className='col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Working Hours</Form.Label></div>
                                {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
                                    <div key={day} className="col-md-10">
                                        <label className="col-md-2 text-right">{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                                        <TimePicker use12Hours format="h:mm A" minuteStep={1}
                                            value={workingHours[`${day}_open`] ? moment(workingHours[`${day}_open`], 'h:mm A') : null}
                                            onChange={(time, timeString) => handleTimeChange(timeString, day, 'open')}
                                            placeholder="Select AM/PM"
                                        />&nbsp; &nbsp;
                                        <TimePicker use12Hours format="h:mm A" minuteStep={1}
                                            value={workingHours[`${day}_close`] ? moment(workingHours[`${day}_close`], 'h:mm A') : null}
                                            onChange={(time, timeString) => handleTimeChange(timeString, day, 'close')}
                                            placeholder="Select AM/PM"
                                        />
                                        {errors[day] && <p style={{ color: 'red' }}>{errors[day]}</p>}
                                    </div>
                                ))}
                            </div>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Contact Name</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="contactName"
                                            value={formData.contactName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.contactName && <p style={{ color: "red" }}>{errors.contactName}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Contact Number</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="tel"
                                            name="contactNumber"
                                            value={formData.contactNumber}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.contactNumber && <p style={{ color: "red" }}>{errors.contactNumber}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Home Delivery Available</Form.Label></div>
                                    <div className='col-md-10'>
                                        <div className='row p-2'>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="homeDeliveryAvailable"
                                                    value={true}
                                                    checked={formData.homeDeliveryAvailable === true}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="homeDeliveryAvailable"
                                                    value={false}
                                                    checked={formData.homeDeliveryAvailable === false}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Pickup Available</Form.Label></div>
                                    <div className='col-md-10'>
                                        <div className='row p-2'>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="pickupAvailable"
                                                    value={true}
                                                    checked={formData.pickupAvailable === true}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="pickupAvailable"
                                                    value={false}
                                                    checked={formData.pickupAvailable === false}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Bank Name</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="bankName"
                                            value={formData.bankName}
                                            // onChange={handleInputChange}
                                            onChange={(e) => handleInputChange({ target: { name: e.target.name, value: e.target.value.toUpperCase() } })}
                                            required
                                        />
                                        {errors.bankName && <p style={{ color: "red" }}>{errors.bankName}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Account Holder Name</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="accountHolderName"
                                            value={formData.accountHolderName}
                                            //onChange={handleInputChange}
                                            onChange={(e) => handleInputChange({ target: { name: e.target.name, value: e.target.value.toUpperCase() } })}
                                            required
                                        />
                                        {errors.accountHolderName && <p style={{ color: "red" }}>{errors.accountHolderName}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Bank Account Number</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="bankAccountNumber"
                                            value={formData.bankAccountNumber}
                                            onChange={handleAccount && handleInputChange}
                                            required
                                        />
                                        {errors.bankAccountNumber && <p style={{ color: "red" }}>{errors.bankAccountNumber}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>IFSC Code</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="ifsc"
                                            value={formData.ifsc}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.ifsc && <p style={{ color: "red" }}>{errors.ifsc}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>PAN</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="panNum"
                                            value={formData.panNum}
                                            onChange={(e) => handleInputChange({ target: { name: e.target.name, value: e.target.value.toUpperCase() } })}
                                            //onChange={handleInputChange}
                                            required
                                        />
                                        {errors.panNum && <p style={{ color: "red" }}>{errors.panNum}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>PAN Image</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="file"
                                            name="panImage"
                                            onChange={handlePanChange}
                                            accept="image/*"
                                            required
                                        />
                                        {errors.panImage && <p style={{ color: "red" }}>{errors.panImage}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Pan Link with Aadhar</Form.Label></div>
                                    <div className='col-md-10'>
                                        <div className='row p-2'>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="panLinkWithAadhar"
                                                    value={true}
                                                    checked={formData.panLinkWithAadhar === true}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                            <div className='pl-2'>
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="panLinkWithAadhar"
                                                    value={false}
                                                    checked={formData.panLinkWithAadhar === false}
                                                    onChange={handleRadioInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>GST Number</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control
                                            type="text"
                                            name="gstNumber"
                                            value={formData.gstNumber}
                                            onChange={handleInputChange}
                                        />
                                        {errors.gstNumber && <p style={{ color: "red" }}>{errors.gstNumber}</p>}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Certifications</Form.Label></div>
                                    <div className='col-md-10'>
                                        {formData.certifications.map((cert, index) => (
                                            <div key={index} className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Certification Name"
                                                    value={cert.name}
                                                    onChange={(e) =>
                                                        handleCertifications(index, 'name', e.target.value)
                                                    }
                                                    className="mb-2"
                                                />
                                                <Form.Control
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) =>
                                                        handleCertificateChange(index, 'image', e.target.files[0])
                                                    }
                                                />
                                            </div>
                                        ))}
                                        <div className="popup-buttons">
                                            <Button className="secondary" variant="secondary" onClick={addCertificationField}>
                                                Add Certifications
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>
                            <div className="popup-buttons">
                                <Button className="secondary" variant="secondary" onClick={() => setFormVisible(false)}>
                                    Cancel
                                </Button>
                                <Button className="secondary" variant="primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </div>
                        </Form>
                        {/* </div> */}
                    </div>
                )}

                {showApprovalPopup && (
                    <div className="staff-popup-overlay text-center">
                        <div className="popup-content text-center">
                            <p>You do need permission to add your mart.<br></br>Send an email to <strong>moderation@aajubaaju.in</strong> to get permission.</p>
                            <div className="popup-buttons">
                                <Button className="secondary" variant="secondary" onClick={() => setShowApprovalPopup(false)}>
                                    Ok
                                </Button>
                            </div>
                        </div>
                    </div>
                )}



                <Table striped bordered hover className="mt-4">
                    <thead>
                        <tr>
                            <th>S.no.</th>
                            <th>Mart Name</th>
                            <th>Status</th>
                            <th>Posted Datate</th>
                            <th>Last Updated date</th>
                            <th>Open</th>
                            <th>Actions</th>
                            <th>Inventory Manage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {marts.map((mart, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{mart.martName}</td>
                                <td>{mart.status}</td>
                                <td>{mart.postedDate}</td>
                                <td>{mart.lastUpdatedOn}</td>
                                <td>
                                    <label>
                                        {/* <input
                                            type="checkbox"
                                            checked={isOpen}
                                            onChange={toggleOpen}
                                            style={{ display: "none" }}
                                        /> */}
                                        <div
                                            style={{
                                                width: "50px",
                                                height: "25px",
                                                background: mart.open ? "green" : "gray",
                                                borderRadius: "25px",
                                                position: "relative",
                                                transition: "background 0.3s",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    background: "white",
                                                    borderRadius: "50%",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: mart.open ? "27px" : "3px",
                                                    transform: "translateY(-50%)",
                                                    transition: "left 0.3s",
                                                }}
                                            ></div>
                                        </div>
                                    </label>
                                </td>
                                <td>
                                    <Button
                                        variant="warning"
                                        onClick={() => handleEditMart(mart.id)}
                                    >
                                        Edit
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        variant="success"
                                        onClick={() => history.push(`/mart/${mart.id}/product-inventory`)}
                                        className="ml-2"
                                    >
                                        Add Product Inventory
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

            </div>
            <div className={`listings row ${styles.large_rect}`}>
                {!props.isAdmin && <LargeRectangle spot={"-1"} />}
            </div>
            <div className={`${styles.billBoard} row`} style={{ backgroundColor: '#e5e5f2', paddingTop: '100px', paddingBottom: '100px' }}>
                {!props.isAdmin && <BillBoard spot={"-1"} />}
            </div>
        </div>
    );
};

export default ManageMart;
