import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Form, Button, Table } from "react-bootstrap";
import { TitleHeading } from "../UIElements/headings/TitleHeading";
import styles from '../listings/forms/addpost.module.css';
import style from '../UIElements/Form_label/FormLabels.module.css'
import LargeRectangle from "../advertisements/LargeRectangle";
import BillBoard from "../advertisements/BillBoard";
import authservice from "../Authentication/service/authservice";
import { notifyUser, type } from '../utility/notifyUser';

const ProductInventory = (props, selectedProduct) => {
    const { id } = useParams(); // Mart ID from URL
    const [showForm, setShowForm] = useState(false); // Toggle form visibility
    const [products, setProducts] = useState([]);
    const [productId, setProductId] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [formData, setFormData] = useState({
        id: null,
        martId: null,
        productName: "",
        productDescription: "",
        productCategory: "",
        sizeStockPrices: [{ size: "", stockQuantity: "", productPrice: "" }], // Ensure empty strings
        productImages: []
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/get-by-mart/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    setProducts(response.data); // Populate the marts state with API data

                } else {
                    alert("Failed to fetch mart data.");
                }
            })
            .catch((error) => {
                console.error("Error fetching mart data:", error);
            })
            .finally(() => setLoading(false));
    }, []);

    // Handle text input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle size, stock, and price changes
    const handleSizeChange = (index, field, value) => {
        const updatedSizes = [...formData.sizeStockPrices];
        updatedSizes[index][field] = value;
        setFormData({ ...formData, sizeStockPrices: updatedSizes });
    };

    // Check if all previous size entries are filled
    const areAllSizesFilled = () => {
        return formData.sizeStockPrices.every(entry =>
            entry.size.trim() !== "" &&
            entry.stockQuantity !== "" &&
            entry.productPrice !== ""
        );
    };

    // Add another size entry
    const addSizeEntry = () => {
        if (areAllSizesFilled()) {
            setFormData({
                ...formData,
                sizeStockPrices: [...formData.sizeStockPrices, { size: "", stockQuantity: "", productPrice: "" }]
            });
        }
    };

    // Handle image upload (multiple images)
    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to array
        const imageUrls = files.map(file => URL.createObjectURL(file)); // Preview images locally
        setFormData({ ...formData, productImages: [...formData.productImages, ...imageUrls] });
    };

    // Remove an image from the list
    const removeImage = (index) => {
        setFormData({
            ...formData,
            productImages: formData.productImages.filter((_, i) => i !== index)
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const ProductDTO = {
            ...formData,
            martId: Number(id),
            id: null,
            sizeStockPrices: formData.sizeStockPrices.map(entry => ({
                size: entry.size.trim(),
                stockQuantity: Number(entry.stockQuantity), 
                productPrice: Number(entry.productPrice)
            }))
        };
        try {
            if (currentProduct) {
                await axios.put(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/update/${currentProduct.id}`, ProductDTO);
                notifyUser("Success", "Product updated successfully");
                setShowForm(false);
                setProducts((prevProducts) =>
                    prevProducts.map((product) => product.id === currentProduct.id ? { ...product, ...formData } : product)
                );
                
            }
            else {
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/add`, ProductDTO);
                notifyUser("Success", "Mart added successfully", "success");
                setProducts([...products, { ...formData}]);
                setShowForm(false);
            }
        } catch (error) {
            console.error("Error adding product:", error);
        }
    };

    const handleProductMart = async (products = null, e) => {
        setCurrentProduct(products);
        setShowForm(true);

        if (products) {
            setFormData({
                productName: products.productName || '',
                productDescription: products.productDescription || '',
                productCategory: products.productCategory || '',
                sizeStockPrices: products.sizeStockPrices.map(entry => ({
                    size: entry.size.trim(),
                    stockQuantity: Number(entry.stockQuantity), 
                    productPrice: Number(entry.productPrice)
                })),
                productImages: products.productImages
            });
        } else {
            setFormData({
                productName: '',
                productDescription: '',
                productCategory: '',
                sizeStockPrices: [{ size: "", stockQuantity: "", productPrice: "" }],
                productImages: []
            });
        }
    };

    const handleDelete = async (productId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/remove/${productId}`);
            notifyUser("Success", "Product deleted successfully", "success");
    
            // Remove the product from state
            setProducts(prevProducts => prevProducts.filter(product => product.id !== productId));
        } catch (error) {
            console.error("Error deleting product:", error);
            notifyUser("Error", "Failed to delete product", "error");
        }
    };

    return (
        <div className="admin-view-head">
            {/* Button to Show the Form */}
            <div className="mt-4 g-2 col-md-12">
                <TitleHeading text='Inventry Management' />
                {!showForm ? (
                    <Button variant="primary" onClick={() => setShowForm(true)}>Add New Product</Button>
                ) : (
                    <div>
                        <Button variant="danger" className="popup-close-btn" onClick={() => setShowForm(false)}>
                            &times;
                        </Button>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Product Name</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control type="text" name="productName" value={formData.productName} onChange={handleInputChange} required />
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Description</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control as="textarea" name="productDescription" value={formData.productDescription} onChange={handleInputChange} required />
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}> <Form.Label>Category</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control type="text" name="productCategory" value={formData.productCategory} onChange={handleInputChange} required />
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}> <Form.Label>Size, Stock & Price</Form.Label></div>
                                    <div className='col-md-10'>
                                        {formData.sizeStockPrices.map((entry, index) => (
                                            <div key={index}>
                                                <Form.Control type="text" placeholder="Size" value={entry.size} onChange={(e) => handleSizeChange(index, "size", e.target.value)} required />
                                                <Form.Control type="number" placeholder="Stock" value={entry.stockQuantity} onChange={(e) => handleSizeChange(index, "stockQuantity", e.target.value)} required />
                                                <Form.Control type="number" placeholder="Price" value={entry.productPrice} onChange={(e) => handleSizeChange(index, "productPrice", e.target.value)} required />
                                            </div>
                                        ))}
                                        <Button
                                            variant="secondary"
                                            onClick={addSizeEntry}
                                            disabled={!areAllSizesFilled()}
                                            className="mt-2"
                                        >
                                            Add More Sizes
                                        </Button></div>
                                </div>

                            </Form.Group>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-right`}> <Form.Label>Product Images</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control type="file" multiple accept="image/*" onChange={handleImageUpload} />
                                    </div>
                                    <div className="mt-3">
                                        {formData.productImages.map((img, index) => (
                                            <div key={index} style={{ display: "inline-block", position: "relative", margin: "5px" }}>
                                                <img src={img} alt="Product" style={{ width: "100px", height: "100px", objectFit: "cover" }} />
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    style={{ position: "absolute", top: 0, right: 0 }}
                                                    onClick={() => removeImage(index)}
                                                >
                                                    ×
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>
                            <div className="popup-buttons">
                                <Button className="secondary" variant="danger" onClick={() => setShowForm(false)}>Cancel</Button>
                                <Button className="secondary" variant="primary" type="submit">Submit</Button>
                            </div>
                        </Form>
                    </div>
                )}
                <Table striped bordered hover className="mt-4">
                    <thead>
                        <tr>
                            <th>S.no.</th>
                            <th>Product Name</th>
                            <th>Category</th>
                            <th>Description	</th>
                            <th>Actions</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{product.productName}</td>
                                <td>{product.productCategory}</td>
                                <td>{product.productDescription}</td>
                                <td>
                                    <Button
                                        variant="warning"
                                        onClick={() => handleProductMart(product)}
                                    >
                                        ✏️
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        className="delete-icon"
                                        variant="warning"
                                        onClick={() => handleDelete(product.id)}
                                    >
                                       <i class="fa fa-trash" aria-hidden="true"></i>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div><br></br>
            <div className={`listings row ${styles.large_rect}`}>
                {!props.isAdmin && <LargeRectangle spot={"-1"} />}
            </div>
            <div className={`${styles.billBoard} row`} style={{ backgroundColor: '#e5e5f2', paddingTop: '100px', paddingBottom: '100px' }}>
                {!props.isAdmin && <BillBoard spot={"-1"} />}
            </div>
        </div>
    );
};

export default ProductInventory;
