import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import style from "../UIElements/Form_label/FormLabels.module.css";
import { validEmailRegex, mobilenumberRegex, ifscRegex, validateForm, pan, gstNumber } from '../RegxConst'
import { Form, Button, Table } from 'react-bootstrap';
import { TitleHeading } from '../UIElements/headings/TitleHeading';
import styles from '../listings/forms/addpost.module.css';
import BillBoard from '../advertisements/BillBoard';
import LargeRectangle from '../advertisements/LargeRectangle';
import { notifyUser, type } from '../utility/notifyUser';
import SetLocation from '../SetFilters/SetLocation/SetLocation';
import authservice from '../Authentication/service/authservice';
import SetCategory from '../SetFilters/SetCategory/SetCategory';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import { matchPath } from "react-router-dom";

const EditMart = (props) => {
    const { id } = useParams()
    const location = useLocation();
    const history = useHistory();
    const [readOnlyMode, setReadOnlyMode] = useState(false);
    const [formVisible, setFormVisible] = useState(false);
    const [thumbnailImage, setThumbnailImage] = useState(null);
    const [panImage, setPanImage] = useState(null);
    const [certificateImage, setCertificateImg] = useState(null);
    const [isThumbnailPicValid, setIsThumbnailPicValid] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        id: null,
        martName: "",
        businessDescription: "",
        businessAddress: "",
        contactName: "",
        contactNumber: "",
        thumbnailDescription: "",
        thumbnailImage: null,
        homeDeliveryAvailable: false,
        pickupAvailable: false,
        categories: [],
        subCategories: [],
        certifications: [],
        workingHours: [],
        openingHoursList: [],
        country: [],
        states: [],
        statesAll: [],
        cities: [],
        localities: [],
        neighbourhoods: [],
        bankName: "",
        accountHolderName: "",
        bankAccountNumber: "",
        ifsc: "",
        panNum: "",
        panImage: null,
        gstNumber: "",
    });
    const [marts, setMarts] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [categoryValidation, setCategoryValidation] = useState(null);
    const [subCategoryValidation, setSubCategoryValidation] = useState(null);
    const [allLocations, setAllLocations] = useState([]);
    const [bankAccountNumber, setBankAccountNumber] = useState("");
    const bankAvailabilityTimer = useRef(null);

    useEffect(() => {
        const match = matchPath(location.pathname, {
            path: "/admin-panel/manage-mart/view-mart/:id",
            exact: true,
            strict: false,
        });

        if (match) {
            setReadOnlyMode(true);
        } else {
            setReadOnlyMode(false);
        }
    }, [location.pathname]);

    const [workingHours, setWorkingHours] = useState({
        monday_open: "", monday_close: "",
        tuesday_open: "", tuesday_close: "",
        wednesday_open: "", wednesday_close: "",
        thursday_open: "", thursday_close: "",
        friday_open: "", friday_close: "",
        saturday_open: "", saturday_close: "",
        sunday_open: "", sunday_close: ""
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/get-single-mart/${id}`)
            .then(response => {
                const martData = response.data;
                console.log("API Response:", martData);

                if (martData.workingHours) {
                    let formattedHours = {};

                    martData.workingHours.forEach(({ day, openingTime, closingTime }) => {
                        const dayLower = day.toLowerCase();
                        formattedHours[`${dayLower}_open`] = openingTime;
                        formattedHours[`${dayLower}_close`] = closingTime;
                    });

                    console.log("Formatted Working Hours:", formattedHours);
                    setWorkingHours(formattedHours);
                }
            })
            .catch(error => console.error("Error fetching mart data:", error));
    }, [id]);

    const handleTimeChange = (timeString, day, type) => {
        setWorkingHours((prev) => ({
            ...prev,
            [`${day}_${type}`]: timeString
        }));

        if (type === 'close' && workingHours[`${day}_open`]) {
            const fromTime = moment(workingHours[`${day}_open`], 'h:mm A');
            const toTime = moment(timeString, 'h:mm A');
            if (!toTime.isAfter(fromTime)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [day]: 'Closing time must be after opening time'
                }));
            } else {
                setErrors((prevErrors) => {
                    const newErrors = { ...prevErrors };
                    delete newErrors[day];
                    return newErrors;
                });
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleRadioInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value === "true", // Convert string to boolean
        }));
    };


    const handleThumbnailChange = async (event) => {
        const file = event.target.files[0];

        if (file && file.type.startsWith("image")) {
            const reader = new FileReader();
            reader.onload = () => {
                setThumbnailImage(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append("photo", file);

            axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-pan-card`, formData).then(response => {
                if (response.status === 200) {
                    const uploadedFileName = response.data;
                    setFormData(prev => ({
                        ...prev,
                        thumbnailImage: uploadedFileName,
                    }));
                    setIsThumbnailPicValid(true);
                } else {
                    setIsThumbnailPicValid(false);
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        thumbnailImage: "Please upload a square image with minimum 425px x 425px.",
                    }));
                    notifyUser(
                        "Error",
                        "Please upload a square image with minimum 425px x 425px.",
                        "error"
                    );
                }
            })
                .catch(error => {
                    setIsThumbnailPicValid(false);
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        thumbnailImage: "Please upload a square image with minimum 425px x 425px.",
                    }));
                });
        } else {
            setIsThumbnailPicValid(false);
            setErrors((prevErrors) => ({
                ...prevErrors,
                thumbnailImage: "Please select a valid image file",
            }));
            notifyUser("Invalid File", "Please select a valid image file", "warning");
        }
    };

    const handlePanChange = async (event) => {
        const file = event.target.files[0];

        if (file && file.type.startsWith("image")) {
            const reader = new FileReader();
            reader.onload = () => {
                setPanImage(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append("photo", file);

            axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-pan-card`, formData).then(response => {
                if (response.status === 200) {
                    const uploadedFileName = response.data;
                    setFormData(prev => ({
                        ...prev,
                        panImage: uploadedFileName,
                    }));
                }
            }).catch(error => {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    panImage: "Please upload Pan Image.",
                }));
            });
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                panImage: "Please select a valid image file",
            }));
            notifyUser("Invalid File", "Please select a valid image file", "warning");
        }
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.files[0],
        });
    };

    const handleCertificateChange = async (index, field, file) => {
        if (file && file.type.startsWith("image")) {
            const formData = new FormData();
            formData.append("photo", file);

            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-pan-card`,
                    formData
                );

                if (response.status === 200) {
                    handleCertifications(index, field, response.data);
                } else {
                    notifyUser("Error", "Failed to upload certificate image", "error");
                }
            } catch (error) {
                notifyUser("Error", "Error uploading certificate image", "error");
            }
        }
    };


    const handleCertifications = (index, field, value) => {
        const updatedCertifications = [...formData.certifications];
        updatedCertifications[index] = {
            ...updatedCertifications[index],
            [field]: value,
        };
        setFormData({ ...formData, certifications: updatedCertifications });
    };


    const addCertificationField = () => {
        setFormData({
            ...formData,
            certifications: [...formData.certifications, { name: '', image: null }],
        });
    };

    const handleAccount = (e) => {
        if (bankAvailabilityTimer.current) clearTimeout(bankAvailabilityTimer.current);

        const updatedBankAccountNumber = e.target.value.toUpperCase();
        setBankAccountNumber(updatedBankAccountNumber);

        bankAvailabilityTimer.current = setTimeout(async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/abmgr/auth/check-bank-account-number?bankAccountNumber=${updatedBankAccountNumber}`
                );
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    bankAccountNumber: response.data,
                }));
            } catch (error) {
                console.error("Error checking bank account number:", error);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    bankAccountNumber: "Unable to verify account number",
                }));
            }
        }, 1000);
    };


    useEffect(() => {
        return () => {
            if (bankAvailabilityTimer.current) clearTimeout(bankAvailabilityTimer.current);
        };
    }, []);


    const validateFormValues = (values) => {
        const errors = {};
        let isValid = true;

        // Helper functions for specific validations
        const isEmpty = (value) => !value;
        const isValidPhone = (contactNumber) => mobilenumberRegex.test(contactNumber); // Adjust regex as per requirement
        const isValidIFSC = (ifsc) => ifscRegex.test(ifsc); // Example IFSC regex
        const isValidPAN = (panNum) => pan.test(panNum); // Example PAN regex
        const isValidGST = (gst) => gstNumber.test(gst); // Example GST regex

        // Required field validations
        if (isEmpty(values.martName)) {
            isValid = false;
            errors.martName = 'Mart name is required';
        }
        if (isEmpty(values.businessDescription)) {
            isValid = false;
            errors.businessDescription = 'Business description is required';
        }
        if (!thumbnailImage) {
            isValid = false;
            errors.thumbnailImage = 'Thumbnail Image is required';
        }
        if (isEmpty(values.thumbnailDescription)) {
            isValid = false;
            errors.thumbnailDescription = 'Thumbnail description is required';
        }
        if (isEmpty(values.businessAddress)) {
            isValid = false;
            errors.businessAddress = 'Business address is required';
        }
        if (isEmpty(values.contactName)) {
            isValid = false;
            errors.contactName = 'Contact name is required';
        }
        if (!isValidPhone(values.contactNumber)) {
            isValid = false;
            errors.contactNumber = 'Valid phone number is required';
        }
        if (isEmpty(values.categories)) {
            isValid = false;
            errors.categories = 'At least one category is required';
        }
        if (isEmpty(values.subCategories)) {
            isValid = false;
            errors.subCategories = 'At least one subcategory is required';
        }
        if (isEmpty(values.bankName)) {
            isValid = false;
            errors.bankName = 'Bank name is required';
        }
        if (isEmpty(values.accountHolderName)) {
            isValid = false;
            errors.accountHolderName = 'Account holder name is required';
        }
        if (isEmpty(values.bankAccountNumber)) {
            isValid = false;
            errors.bankAccountNumber = 'Bank account number is required';
        }
        if (!isValidIFSC(values.ifsc)) {
            isValid = false;
            errors.ifsc = 'Valid IFSC code is required';
        }
        if (!isValidPAN(values.panNum)) {
            isValid = false;
            errors.panNum = 'Valid PAN is required';
        }
        // if (isEmpty(values.panImage)) {
        //     isValid = false;
        //     errors.panImage = 'PAN image is required';
        // }
        if (isEmpty(values.panLinkWithAadhar)) {
            isValid = false;
            errors.panLinkWithAadhar = 'PAN linked with Aadhar is required';
        }
        // if (!isValidGST(values.gstNumber)) {
        //     isValid = false;
        //     errors.gstNumber = 'Valid GST number is required';
        // }

        // Set errors and return overall validity
        setErrors(errors);
        return isValid;
    };

    useEffect(() => {
        const fetchAllCategories = async () => {
            try {
                let userId = authservice.getCurrentUser().id;
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/abmgr/category/get-mart-subcategories/${userId}`
                );

                const categoriesData = response.data;
                console.log(categoriesData)
                setAllCategories(categoriesData);

                if (categoriesData.length > 0) {
                    setFormData((prev) => ({
                        ...prev,
                        categories: [categoriesData[0].categoryId],
                    }));
                }
                if (props.fullCategory) {
                    props.fullCategory(categoriesData);
                }
            } catch (error) {
                console.log(error, 'Getting data failed');
            }
        };

        fetchAllCategories();
    }, [props]);

    const showCategory = true;

    const handleNewCategory = (data) => {
        setAllCategories((prevCategories) => [...prevCategories, data]);
    };

    const handleCategoryChange = (selectedCategories) => {
        const categoriesArray = selectedCategories.map(category => category.value);
        setFormData(prev => ({
            ...prev,
            categories: categoriesArray
        }));
    };

    const handleSubCategoryChange = (selectedSubCategories) => {
        const subCategoriesArray = selectedSubCategories.map(subCategory => subCategory.value);
        setFormData(prev => ({
            ...prev,
            subCategories: subCategoriesArray
        }));
    };

    const handleSetCategoryValidationMethod = (m) => {
        setCategoryValidation(m);
    };

    const handleSetSubCategoryValidationMethod = (m) => {
        setSubCategoryValidation(m);
    };

    useEffect(() => {
        const fetchAllLocations = async () => {
            try {
                const [getFullAddress] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getallstates`),
                ]);
                setAllLocations(getFullAddress.data);
                if (props.fullAddress) {
                    props.fullAddress(getFullAddress.data);
                }
            } catch (error) {
                console.log(error, 'Getting data failed');
            }
        };

        fetchAllLocations();
    }, [props]);

    const handleNewLocation = (data) => {
        setAllLocations((prevLocations) => [...prevLocations, data]);
    };

    const handleStateChange = (selectedStates) => {
        const statesArray = selectedStates.map(state => state.value);
        setFormData(prev => ({
            ...prev,
            states: statesArray
        }));
    };

    const handleCityChange = (selectedCities) => {
        const citiesArray = selectedCities.map(city => city.value);
        setFormData(prev => ({
            ...prev,
            cities: citiesArray
        }));
    };

    const handleLocalityChange = (selectedLocalities) => {
        const localitiesArray = selectedLocalities.map(locality => locality.value);
        setFormData(prev => ({
            ...prev,
            localities: localitiesArray
        }));
    };

    const handleNeighbourhoodChange = (selectedNeighbourhoods) => {
        const neighbourhoodArray = selectedNeighbourhoods.map(neighbourhood => neighbourhood.value);
        setFormData(prev => ({
            ...prev,
            neighbourhoods: neighbourhoodArray
        }));
    };

    useEffect(() => {
        if (location.state && location.state.mart) {
            setFormData({
                ...formData,
                ...location.state.mart,
            });
        }
    }, [location.state]);

    // Handle form submission
    const handleUpdate = async () => {
        // if (validateForm()) {
        try {
            const payload = Object.keys(workingHours).map(dayKey => {
                const [day, type] = dayKey.split("_"); // Extract day and type (open/close)
                return { 
                    day, 
                    openingTime: workingHours[`${day}_open`] || "", 
                    closingTime: workingHours[`${day}_close`] || ""
                };
            }).reduce((acc, curr) => {
                // Group data by day
                let existing = acc.find(item => item.day === curr.day);
                if (existing) {
                    existing.openingTime = curr.openingTime;
                    existing.closingTime = curr.closingTime;
                } else {
                    acc.push(curr);
                }
                return acc;
            }, []);

            const thumbnailImage = formData.thumbnailImage
                ? formData.thumbnailImage.split("/").pop()
                : formData.thumbnailImage;
            const panImage = formData.panImage
                ? formData.panImage.split("/").pop()
                : formData.panImage;
            const certificateImage = formData.certificateImage
                ? formData.panImage.split("/").pop()
                : formData.certificateImage;
            const martDTO = {
                id: formData.id,
                userId: authservice.getCurrentUserid(),
                name: formData.martName,
                businessDescription: formData.businessDescription,
                thumbnailImage: thumbnailImage,
                thumbnailDescription: formData.thumbnailDescription,
                businessAddress: formData.businessAddress,
                contactName: formData.contactName,
                contactNumber: formData.contactNumber,
                // certifications: [],
                certifications: formData.certifications.map(cert => ({
                    name: cert.name,
                    image: cert.image,
                })),
                homeDeliveryAvailable: formData.homeDeliveryAvailable,
                pickupAvailable: formData.pickupAvailable,
                workingHours: payload,
                categories: formData.categories,
                subCategories: formData.subCategories,
                country: 'India',
                states: formData.states,
                cities: formData.cities,
                localities: formData.localities,
                neighbourhoods: formData.neighbourhoods,
                bankName: formData.bankName,
                accountHolderName: formData.accountHolderName,
                bankAccountNumber: formData.bankAccountNumber,
                ifsc: formData.ifsc,
                pan: formData.panNum,
                panImage: panImage,
                panLinkWithAadhar: formData.panLinkWithAadhar,
                gstNumber: formData.gstNumber,
                lattitude: null,
                longitude: null

            };
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/update`,
                martDTO
            );
            if (response.status === 200) {
                notifyUser('Update mart', 'Your Mart Updated Successfully', type.SUCCESS)
                setFormVisible(false);
                history.push(`/mart/${authservice.getCurrentUserName()}`);
            }
        } catch (error) {
            console.error("Error updating mart:", error);
            notifyUser('Update Mart', 'Failed to update the mart', type.DANGER)
        }
        //}
    };



    useEffect(() => {
        const fetchLocationsAndMartData = async () => {
            try {
                const martResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/get-single-mart/${id}`);

                if (martResponse.status === 200) {
                    setFormData(prev => ({
                        ...prev,
                        ...martResponse.data,
                        martName: martResponse.data.name,
                        panNum: martResponse.data.pan,

                    }));
                } else {
                    alert("Failed to fetch data.");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                alert("Error fetching data.");
            }
        };

        fetchLocationsAndMartData();
    }, [id]);




    return (
        <div className="admin-view-head">
            <div className="mt-4 g-2 col-md-12">
                {!readOnlyMode && <TitleHeading text='Edit Mart' />}
                <div className="p-3 border rounded bg-light">
                    <Form>
                        {/* Mart Name */}
                        <Form.Group>
                            <div className="row col-md-10 justify-content-center p-1 ml-4">
                                <div className={`${style.addpost_label} col-md-2 text-right`}>
                                    <Form.Label>Mart Name</Form.Label>
                                </div>
                                <div className="col-md-10">
                                    <Form.Control
                                        type="text"
                                        name="martName"
                                        value={formData.martName}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.martName}
                                        disabled={readOnlyMode}
                                    />
                                    {errors.martName && (
                                        <p style={{ color: "red" }}>{errors.martName}</p>
                                    )}
                                </div>
                            </div>
                        </Form.Group>

                        {/* Business Description */}
                        <Form.Group>
                            <div className="row col-md-10 justify-content-center p-1 ml-4">
                                <div className={`${style.addpost_label} col-md-2 text-right`}>
                                    <Form.Label>Business Description</Form.Label>
                                </div>
                                <div className="col-md-10">
                                    <Form.Control
                                        as="textarea"
                                        name="businessDescription"
                                        value={formData.businessDescription}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.businessDescription}
                                        disabled={readOnlyMode}
                                    />
                                    {errors.businessDescription && (
                                        <p style={{ color: "red" }}>{errors.businessDescription}</p>
                                    )}
                                </div>
                            </div>
                        </Form.Group>

                        <SetCategory
                            allCategories={allCategories}
                            showCategory={showCategory}
                            setCategoryValidationMethod={handleSetCategoryValidationMethod}
                            setSubCategoryValidationMethod={handleSetSubCategoryValidationMethod}
                            heading="Set Category"
                            isMulti
                            disabled={readOnlyMode}
                            _handleNewCategory={handleNewCategory}
                            newFilterForm={true}
                            selectedCategories={formData.categories}
                            selectedSubCategories={formData.subCategories}
                            _handleCategory={handleCategoryChange}
                            _handleSubCategory={handleSubCategoryChange}
                            wrapperClass="col-md-12"
                            labelClass="col-md-2"
                        />

                        <SetLocation
                            heading="Area Served"
                            isMulti
                            allLocations={allLocations}
                            _handleNewLocation={handleNewLocation}
                            newFilterForm={true}
                            //disabled={disable}
                            selectedStates={formData.states}
                            selectedCities={formData.cities}
                            selectedLocalities={formData.localities}
                            selectedNeighbourhoods={formData.neighbourhoods}
                            disabled={readOnlyMode}
                            _handleState={handleStateChange}
                            _handleCity={handleCityChange}
                            _handleLocality={handleLocalityChange}
                            _handleNeighbourhood={handleNeighbourhoodChange}
                            wrapperClass="col-md-12"
                            labelClass="col-md-2"
                        />

                        <Form.Group>
                            <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Thumbnail Image</Form.Label></div>
                                <div className='col-md-10'>
                                    <div>
                                        {formData.thumbnailImage && (
                                            <div>
                                                <img
                                                    src={`${process.env.REACT_APP_IMAGES_URL}/${formData.thumbnailImage}`} // Preview the selected image
                                                    alt="Thumbnail Preview"
                                                    style={{ width: '425px', height: '425px', marginBottom: '10px' }}
                                                />
                                            </div>
                                        )}
                                        <Form.Control
                                            type="file"
                                            name="thumbnailImage"
                                            onChange={handleThumbnailChange}
                                            accept="image/*"
                                            disabled={readOnlyMode}
                                        />
                                        {errors.thumbnailImage && <p style={{ color: "red" }}>{errors.thumbnailImage}</p>}
                                    </div>
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Thumbnail Description</Form.Label></div>
                                <div className='col-md-10'>
                                    <Form.Control
                                        as="textarea"
                                        name="thumbnailDescription"
                                        value={formData.thumbnailDescription}
                                        onChange={handleInputChange}
                                        disabled={readOnlyMode}
                                    />
                                    {errors.thumbnailDescription && <p style={{ color: "red" }}>{errors.thumbnailDescription}</p>}
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Business Address</Form.Label></div>
                                <div className='col-md-10'>
                                    <Form.Control
                                        type='text'
                                        name="businessAddress"
                                        value={formData.businessAddress}
                                        onChange={handleInputChange}
                                        disabled={readOnlyMode}
                                        required
                                    />
                                    {errors.businessAddress && <p style={{ color: "red" }}>{errors.businessAddress}</p>}
                                </div>
                            </div>
                        </Form.Group>

                        <div className='col-md-10 justify-content-center p-1 ml-4'>
                            <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Working Hours</Form.Label></div>
                            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
                                <div key={day} className="col-md-10">
                                    <label className="col-md-2 text-right">{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                                    <TimePicker use12Hours format="h:mm A" minuteStep={1}
                                        value={workingHours[`${day}_open`] ? moment(workingHours[`${day}_open`], 'h:mm A') : null}
                                        onChange={(time, timeString) => handleTimeChange(timeString, day, 'open')}
                                        placeholder="Select AM/PM"
                                    />&nbsp; &nbsp;
                                    <TimePicker use12Hours format="h:mm A" minuteStep={1}
                                        value={workingHours[`${day}_close`] ? moment(workingHours[`${day}_close`], 'h:mm A') : null}
                                        onChange={(time, timeString) => handleTimeChange(timeString, day, 'close')}
                                        placeholder="Select AM/PM"
                                    />
                                    {errors[day] && <p style={{ color: 'red' }}>{errors[day]}</p>}
                                </div>
                            ))}
                        </div>

                        {/* Contact Name */}
                        <Form.Group>
                            <div className="row col-md-10 justify-content-center p-1 ml-4">
                                <div className={`${style.addpost_label} col-md-2 text-right`}>
                                    <Form.Label>Contact Name</Form.Label>
                                </div>
                                <div className="col-md-10">
                                    <Form.Control
                                        type="text"
                                        name="contactName"
                                        value={formData.contactName}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.contactName}
                                        disabled={readOnlyMode}
                                    />
                                    {errors.contactName && (
                                        <p style={{ color: "red" }}>{errors.contactName}</p>
                                    )}
                                </div>
                            </div>
                        </Form.Group>

                        {/* Contact Number */}
                        <Form.Group>
                            <div className="row col-md-10 justify-content-center p-1 ml-4">
                                <div className={`${style.addpost_label} col-md-2 text-right`}>
                                    <Form.Label>Contact Number</Form.Label>
                                </div>
                                <div className="col-md-10">
                                    <Form.Control
                                        type="tel"
                                        name="contactNumber"
                                        value={formData.contactNumber}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.contactNumber}
                                        disabled={readOnlyMode}
                                    />
                                    {errors.contactNumber && (
                                        <p style={{ color: "red" }}>{errors.contactNumber}</p>
                                    )}
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Home Delivery Available</Form.Label></div>
                                <div className='col-md-10'>
                                    <div className='row p-2'>
                                        <div className='pl-2'>
                                            <Form.Check
                                                type="radio"
                                                label="Yes"
                                                name="homeDeliveryAvailable"
                                                value={true}
                                                checked={formData.homeDeliveryAvailable === true}
                                                onChange={handleRadioInputChange}
                                                disabled={readOnlyMode}
                                            />
                                        </div>
                                        <div className='pl-2'>
                                            <Form.Check
                                                type="radio"
                                                label="No"
                                                name="homeDeliveryAvailable"
                                                value={false}
                                                checked={formData.homeDeliveryAvailable === false}
                                                onChange={handleRadioInputChange}
                                                disabled={readOnlyMode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Pickup Available</Form.Label></div>
                                <div className='col-md-10'>
                                    <div className='row p-2'>
                                        <div className='pl-2'>
                                            <Form.Check
                                                type="radio"
                                                label="Yes"
                                                name="pickupAvailable"
                                                value={true}
                                                checked={formData.pickupAvailable === true}
                                                onChange={handleRadioInputChange}
                                                disabled={readOnlyMode}
                                            />
                                        </div>
                                        <div className='pl-2'>
                                            <Form.Check
                                                type="radio"
                                                label="No"
                                                name="pickupAvailable"
                                                value={false}
                                                checked={formData.pickupAvailable === false}
                                                onChange={handleRadioInputChange}
                                                disabled={readOnlyMode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Bank Name</Form.Label></div>
                                <div className='col-md-10'>
                                    <Form.Control
                                        type="text"
                                        name="bankName"
                                        value={formData.bankName}
                                        onChange={handleInputChange}
                                        required
                                        disabled={readOnlyMode}
                                    />
                                    {errors.bankName && <p style={{ color: "red" }}>{errors.bankName}</p>}
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Account Holder Name</Form.Label></div>
                                <div className='col-md-10'>
                                    <Form.Control
                                        type="text"
                                        name="accountHolderName"
                                        value={formData.accountHolderName}
                                        onChange={handleInputChange}
                                        required
                                        disabled={readOnlyMode}
                                    />
                                    {errors.accountHolderName && <p style={{ color: "red" }}>{errors.accountHolderName}</p>}
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Bank Account Number</Form.Label></div>
                                <div className='col-md-10'>
                                    <Form.Control
                                        type="text"
                                        name="bankAccountNumber"
                                        value={formData.bankAccountNumber}
                                        onChange={handleAccount && handleInputChange}
                                        required
                                        disabled={readOnlyMode}
                                    />
                                    {errors.bankAccountNumber && <p style={{ color: "red" }}>{errors.bankAccountNumber}</p>}
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>IFSC Code</Form.Label></div>
                                <div className='col-md-10'>
                                    <Form.Control
                                        type="text"
                                        name="ifsc"
                                        value={formData.ifsc}
                                        onChange={handleInputChange}
                                        required
                                        disabled={readOnlyMode}
                                    />
                                    {errors.ifsc && <p style={{ color: "red" }}>{errors.ifsc}</p>}
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>PAN</Form.Label></div>
                                <div className='col-md-10'>
                                    <Form.Control
                                        type="text"
                                        name="panNum"
                                        value={formData.panNum}
                                        onChange={handleInputChange}
                                        required
                                        disabled={readOnlyMode}
                                    />
                                    {errors.panNum && <p style={{ color: "red" }}>{errors.panNum}</p>}
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}>
                                    <Form.Label>PAN Image</Form.Label>
                                </div>
                                <div className='col-md-10'>
                                    {formData.panImage && (
                                        <div>
                                            <img
                                                src={`${process.env.REACT_APP_IMAGES_URL}/${formData.panImage}`} 
                                                alt="Pan Image"
                                                style={{ width: '600px', height: 'auto', marginBottom: '10px' }}
                                            />
                                        </div>
                                    )}
                                    {/* File input for uploading new Pan Image */}
                                    <Form.Control
                                        type="file"
                                        name="panImage"
                                        onChange={handlePanChange}
                                        accept="image/*"
                                        disabled={readOnlyMode}
                                    />
                                    {errors.panImage && <p style={{ color: "red" }}>{errors.panImage}</p>}
                                </div>
                            </div>
                        </Form.Group>


                        <Form.Group>
                            <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>Pan Link with Aadhar</Form.Label></div>
                                <div className='col-md-10'>
                                    <div className='row p-2'>
                                        <div className='pl-2'>
                                            <Form.Check
                                                type="radio"
                                                label="Yes"
                                                name="panLinkWithAadhar"
                                                value={true}
                                                checked={formData.panLinkWithAadhar === true}
                                                onChange={handleRadioInputChange}
                                                disabled={readOnlyMode}
                                            />
                                        </div>
                                        <div className='pl-2'>
                                            <Form.Check
                                                type="radio"
                                                label="No"
                                                name="panLinkWithAadhar"
                                                value={false}
                                                checked={formData.panLinkWithAadhar === false}
                                                onChange={handleRadioInputChange}
                                                disabled={readOnlyMode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}><Form.Label>GST Number</Form.Label></div>
                                <div className='col-md-10'>
                                    <Form.Control
                                        type="text"
                                        name="gstNumber"
                                        value={formData.gstNumber}
                                        onChange={handleInputChange}
                                        disabled={readOnlyMode}
                                    />
                                    {errors.gstNumber && <p style={{ color: "red" }}>{errors.gstNumber}</p>}
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                <div className={`${style.addpost_label} col-md-2 text-right`}>
                                    <Form.Label>Certifications</Form.Label>
                                </div>
                                <div className='col-md-10'>
                                    {formData.certifications.map((cert, index) => (
                                        <div key={index} className="mb-3">
                                            <Form.Control
                                                type="text"
                                                placeholder="Certification Name"
                                                value={cert.name}
                                                disabled={readOnlyMode}
                                                onChange={(e) =>
                                                    handleCertifications(index, 'name', e.target.value)
                                                }
                                                className="mb-2"
                                            />

                                            {cert.image && (
                                                <div className="image-preview">
                                                    <img
                                                        src={`${process.env.REACT_APP_IMAGES_URL}/${cert.image}`}
                                                        alt={`Certification ${index}`}
                                                        style={{ width: '400px', height: 'auto', marginBottom: '10px' }}
                                                    />
                                                </div>
                                            )}

                                            {/* File input for image upload */}
                                            <Form.Control
                                                type="file"
                                                accept="image/*"
                                                disabled={readOnlyMode}
                                                onChange={(e) =>
                                                    handleCertificateChange(index, 'image', e.target.files[0])
                                                }
                                            />
                                        </div>
                                    ))}
                                    <div className="popup-buttons">
                                        <Button className="secondary" variant="secondary" onClick={addCertificationField} disabled={readOnlyMode}>
                                            Add Certifications
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form.Group>


                        {/* Buttons */}
                        <div className="popup-buttons">
                            <Button variant="primary" onClick={handleUpdate} className="secondary" disabled={readOnlyMode}>
                                Save Changes
                            </Button>
                            <Button
                                variant="secondary" disabled={readOnlyMode}
                                onClick={() => history.push(`/mart/${authservice.getCurrentUserName()}`)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
            {!readOnlyMode && <>
                <div className={`listings row ${styles.large_rect}`}>
                    <LargeRectangle spot={"-1"} />
                </div>
                <div className={`${styles.billBoard} row`} style={{ backgroundColor: '#e5e5f2', paddingTop: '100px', paddingBottom: '100px' }}>
                    <BillBoard spot={"-1"} />
                </div></>}

        </div>
    );
};

export default EditMart;
